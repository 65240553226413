import React from "react";

import SEO from "../components/seo";
import BaseLayout from "../components/layout/baseLayout";
import Container from "../components/container";

const ImprintPage = () => {
  return (
    <div className="bg-gray-100">
      <SEO title="Impressum" />
      <BaseLayout>
        <Container>
          <div className="text-4xl lg:text-4xl xl:text-5xl font-bold mt-12">Impressum</div>
          <p>(Informationen gemäß §5 ECG, §14 UGB u. §25 MedienG)</p>
          <div className="my-12">
            <p><b>E-Mail:</b> patrick.schlager@vispecs.com</p>
            <p className="pb-5"><b>Geschäftsführender Gesellschafter:</b> Patrick Schlager</p>
            <p><b>Rechtsform:</b> Gesellschaft m.b.H.</p>
            <p className="pb-5">Landesgericht Wiener Neustadt</p>
            <p><b>Firmenbuchnummer:</b> FN 527066y</p>
            <p className="pb-5"><b>UID:</b> ATU75207456</p>
            <p><b>Unternehmensgegenstand:</b> Online Vorab-Beratung und Betreuung für optische Sehbehelfe</p>
            <p><b>Behörde gemäß ECG:</b> Magistrat der Stadt Wiener Neustadt</p>
            <p><b>Medieninhaber und Herausgeber:</b> ViSpecs GmbH</p>
            <p><b>Gewerberechtliche Vorschriften:</b> Gewerbeordnung 1994 BGBl 194/1994 (abrufbar unter: https://www.ris.bka.gv.at)</p>
            <p className="pb-5"><b>Website/App:</b> Informationen, Neuigkeiten und Termine über ViSpecs GmbH</p>
            <p>Die ViSepcs GmbH ist Mitglied der Wirtschaftskammer Niederösterreich.</p>
          </div>
        </Container>
      </BaseLayout>
    </div>
  );
}

export default ImprintPage;